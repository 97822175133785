import React, { Component } from 'react';
import { RichText } from '../richTextOptions';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus, getComparisonChoosedItems, getComparisonShowExclusionsAdditionalLimitations, getComparisonShowAlternativeholisticTherapy, getComparisonShowAnnualExamRequirement } from '../../state/app';

const mapDispatchToProps = dispatch => {
     return {
          handleCheckBoxChange: (event) => {
               dispatch({
                    type: 'SET_COMPARISONCHECK',
                    payload: {
                         key: event.target.name || '',
                         value: event.target.checked || false,
                    },
               });
          },

          handleCheckBoxCheckAllChange: (event) => {
               dispatch({
                    type: 'SET_COMPARISONCHECKALL',
               });
          },

          handleCheckBoxUnCheckAllChange: (event) => {
               dispatch({
                    type: 'SET_COMPARISONUNCHECKALL',
               });
          },

          handleChooseProvider: (id) => {
               dispatch({
                    type: 'SET_COMPARISONPROVIDERS',
                    payload: id,
               });
          },
     }
}

class SectionProviderComparison extends Component {
     componentDidMount() {
          if (this.props.providers.length == 1) {
               this.props.handleChooseProvider(this.props.providers[0].id)
          }
     }

     checkAll = (e) => {
          if (e.keyCode === 13) {
               this.props.handleCheckBoxCheckAllChange();
          }
     }

     unCheckAll = (e) => {
          if (e.keyCode === 13) {
               this.props.handleCheckBoxUnCheckAllChange();
          }
     }

     typeEnterCheckBox = (e, checked) => {
          if (e.keyCode === 13) {
               e.target.checked = !checked;
               this.props.handleCheckBoxChange(e);
          }
     }

     render() {
          const comparisonChoosedItems = this.props.comparisonChoosedItems;
          const comparisonShowExclusionsAdditionalLimitations = this.props.comparisonShowExclusionsAdditionalLimitations;
          const comparisonShowAlternativeholisticTherapy = this.props.comparisonShowAlternativeholisticTherapy;
          const comparisonShowAnnualExamRequirement = this.props.comparisonShowAnnualExamRequirement;
          let checkActive = false;

          if (comparisonShowExclusionsAdditionalLimitations && comparisonShowAlternativeholisticTherapy && comparisonShowAnnualExamRequirement) {
               checkActive = true;
          }
          else {
               checkActive = false;
          }

          return (
               <div className="providers-comparison-section container">
                    {this.props.providers.length > 1 && (
                         <nav className="providers-list-button">
                              <ul id="providers-list-button">
                                   {
                                        this.props.providers.map((provider, i) => {
                                             return (<li tabIndex="0" key={i} className={comparisonChoosedItems.includes(provider.id) ? 'choosed' : ''} onClick={() => { this.props.handleChooseProvider(provider.id) }} onKeyDown={(e) => { if (e.keyCode === 13) { this.props.handleChooseProvider(provider.id) } }}><a aria-label={"Link to " + provider.providerName}>{provider.providerName}</a></li>)
                                        })
                                   }
                              </ul>
                         </nav>
                    )}
                    <div className="providers-list-table">
                         <div className="coverage-pricing">
                              <h2>Coverage & Pricing</h2>
                              <div className="table-responsive">
                                   <table className="table">
                                        <thead>
                                             <tr>
                                                  <th scope="col">FEATURE</th>
                                                  <th scope="col">NATIONWIDE</th>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <th scope="col" key={i}>
                                                                           {provider.providerName}
                                                                           {provider.productNameSubText &&
                                                                                <p className="subText">{provider.productNameSubText}</p>
                                                                           }
                                                                      </th>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                        </thead>
                                        <tbody>
                                             <tr>
                                                  <td>Plan name</td>
                                                  <td>{<RichText data={this.props.nationWide.planName}></RichText>}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{<RichText data={provider.planName}></RichText>}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>Base monthly premium</td>
                                                  <td>{this.props.nationWide.baseMonthlyPremium}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{provider.baseMonthlyPremium}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>Wellness rider premium</td>
                                                  <td>{this.props.nationWide.wellnessRiderPremium}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{provider.wellnessRiderPremium}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>
                                                       Additional rider premium(s)
                                                  <span>For total coverage comparable to Nationwide Whole Pet with Wellness</span>
                                                  </td>
                                                  <td>{<RichText data={this.props.nationWide.additionalRiderPremiums}></RichText>}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{<RichText data={provider.additionalRiderPremiums}></RichText>}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>
                                                       Total monthly premium
                                                  <span>Based on 1-yr-old Labrador retriever in Livermore, CA (94550)</span>
                                                  </td>
                                                  <td>{<RichText data={this.props.nationWide.totalMonthlyPremium}></RichText>}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{<RichText data={provider.totalMonthlyPremium}></RichText>}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>
                                                       Deductible
                                             </td>
                                                  <td>{this.props.nationWide.deductible}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{provider.deductible}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>
                                                       Co-pay
                                             </td>
                                                  <td>{this.props.nationWide.coPay}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{provider.coPay}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                        </tbody>
                                   </table>
                              </div>
                              <div className="sample-reimbursement">
                                   <h3> Sample reimbursements</h3>
                                   <p>The following claims are based on three events that happened to the same dog in one policy year/term.</p>
                              </div>
                              <div className="table-responsive second-table">
                                   <table className="table">
                                        <tbody>
                                             <tr>
                                                  <td>
                                                       Pancreatitis
                                                  <span>Bill amount $907.88</span>
                                                  </td>
                                                  <td>{this.props.nationWide.pancreatitis}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{provider.pancreatitis}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>
                                                       Lacerations
                                                  <span>Bill amount $363.27</span>
                                                  </td>
                                                  <td>{this.props.nationWide.lacerations}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{provider.lacerations}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>
                                                       Ear infection
                                                  <span>Bill amount $193.62</span>
                                                  </td>
                                                  <td>{this.props.nationWide.earInfection}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{provider.earInfection}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>
                                                       Wellness/Routine Care
                                                  <span>Bill amount $800.48</span>
                                                  </td>
                                                  <td>{this.props.nationWide.wellnessroutineCare}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{provider.wellnessroutineCare}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                        </tbody>
                                   </table>
                              </div>
                         </div>
                         <div className="key-plan-detail">
                              <h2>Key plan details</h2>
                              <div className="table-responsive">
                                   <table className="table">
                                        <thead>
                                             <tr>
                                                  <th scope="col">FEATURE</th>
                                                  <th scope="col">NATIONWIDE</th>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (<th scope="col" key={i}>{provider.providerName}</th>);
                                                            }
                                                       })
                                                  }
                                             </tr>
                                        </thead>
                                        <tbody>
                                             <tr>
                                                  <td>Use any veterinarian, emergency clinic or specialist</td>
                                                  <td>{<RichText data={this.props.nationWide.useAnyVeterinarianEmergencyClinicOrSpeciali}></RichText>}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{<RichText data={provider.useAnyVeterinarianEmergencyClinicOrSpeciali}></RichText>}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>Exams</td>
                                                  <td>{<RichText data={this.props.nationWide.exams}></RichText>}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{<RichText data={provider.exams}></RichText>}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>Accidents and illnesses, including hospitalizations and surgeries</td>
                                                  <td>{<RichText data={this.props.nationWide.accidentsAndIllnessesIncludingHospitalization}></RichText>}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{<RichText data={provider.accidentsAndIllnessesIncludingHospitalization}></RichText>}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>Chronic and ongoing diseases (example: allergies)</td>
                                                  <td>{<RichText data={this.props.nationWide.chronicAndOngoingDiseases}></RichText>}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{<RichText data={provider.chronicAndOngoingDiseases}></RichText>}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>Cancer</td>
                                                  <td>{<RichText data={this.props.nationWide.cancer}></RichText>}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{<RichText data={provider.cancer}></RichText>}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>Dental surgeries and diseases</td>
                                                  <td>{<RichText data={this.props.nationWide.dentalSurgeriesAndDiseases}></RichText>}
                                                  </td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{<RichText data={provider.dentalSurgeriesAndDiseases}></RichText>}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             <tr>
                                                  <td>Prescription medications</td>
                                                  <td>{<RichText data={this.props.nationWide.prescriptionMedications}></RichText>}</td>
                                                  {
                                                       this.props.providers.map((provider, i) => {
                                                            if (comparisonChoosedItems.includes(provider.id)) {
                                                                 return (
                                                                      <td key={i}>{<RichText data={provider.prescriptionMedications}></RichText>}</td>
                                                                 );
                                                            }
                                                       })
                                                  }
                                             </tr>
                                             {comparisonShowExclusionsAdditionalLimitations && (
                                                  <tr>
                                                       <td>Exclusions & additional limitations</td>
                                                       <td>{<RichText data={this.props.nationWide.exclusionsAdditionalLimitations}></RichText>}</td>
                                                       {
                                                            this.props.providers.map((provider, i) => {
                                                                 if (comparisonChoosedItems.includes(provider.id)) {
                                                                      return (
                                                                           <td key={i}>{<RichText data={provider.exclusionsAdditionalLimitations}></RichText>}</td>
                                                                      );
                                                                 }
                                                            })
                                                       }
                                                  </tr>
                                             )}
                                             {comparisonShowAlternativeholisticTherapy && (
                                                  <tr>
                                                       <td>Alternative/holistic therapy</td>
                                                       <td>{<RichText data={this.props.nationWide.alternativeholisticTherapy}></RichText>}</td>
                                                       {
                                                            this.props.providers.map((provider, i) => {
                                                                 if (comparisonChoosedItems.includes(provider.id)) {
                                                                      return (
                                                                           <td key={i}>{<RichText data={provider.alternativeholisticTherapy}></RichText>}</td>
                                                                      );
                                                                 }
                                                            })
                                                       }
                                                  </tr>
                                             )}

                                             {comparisonShowAnnualExamRequirement && (
                                                  <tr>
                                                       <td>Annual exam requirement</td>
                                                       <td>{<RichText data={this.props.nationWide.annualExamRequirement}></RichText>}</td>
                                                       {
                                                            this.props.providers.map((provider, i) => {
                                                                 if (comparisonChoosedItems.includes(provider.id)) {
                                                                      return (
                                                                           <td key={i}>{<RichText data={provider.annualExamRequirement}></RichText>}</td>
                                                                      );
                                                                 }
                                                            })
                                                       }
                                                  </tr>
                                             )}
                                        </tbody>
                                   </table>
                              </div>
                              <div className="sample-reimbursement">
                                   <h3>More features to compare</h3>
                              </div>
                              <div className="table-footer">
                                   <div className="row select-footer">
                                        <div className="col-md-4">
                                             <label className="checkbox-footer" htmlFor="comparisonShowExclusionsAdditionalLimitations">Exclusions & additional limitations
                                             <input
                                                       type="checkbox"
                                                       checked={comparisonShowExclusionsAdditionalLimitations}
                                                       name="comparisonShowExclusionsAdditionalLimitations"
                                                       onChange={this.props.handleCheckBoxChange}
                                                       onKeyDown={e => this.typeEnterCheckBox(e, comparisonShowExclusionsAdditionalLimitations)}
                                                       id="comparisonShowExclusionsAdditionalLimitations"
                                                  />
                                                  <span className="checkmark"></span>
                                             </label>
                                        </div>
                                        <div className="col-md-4">
                                             <label className="checkbox-footer" htmlFor="comparisonShowAlternativeholisticTherapy">Alternative/Holistic Therapy
                                             <input
                                                       type="checkbox"
                                                       checked={comparisonShowAlternativeholisticTherapy}
                                                       name="comparisonShowAlternativeholisticTherapy"
                                                       onChange={this.props.handleCheckBoxChange}
                                                       onKeyDown={e => this.typeEnterCheckBox(e, comparisonShowAlternativeholisticTherapy)}
                                                       id="comparisonShowAlternativeholisticTherapy"
                                                  />
                                                  <span className="checkmark"></span>
                                             </label>
                                        </div>
                                        <div className="col-md-4">
                                             <label className="checkbox-footer" htmlFor="comparisonShowAnnualExamRequirement">Annual exam requirement
                                             <input
                                                       type="checkbox"
                                                       checked={comparisonShowAnnualExamRequirement}
                                                       type="checkbox"
                                                       onChange={this.props.handleCheckBoxChange}
                                                       onKeyDown={e => this.typeEnterCheckBox(e, comparisonShowAnnualExamRequirement)}
                                                       name="comparisonShowAnnualExamRequirement"
                                                       id="comparisonShowAnnualExamRequirement"
                                                  />
                                                  <span className="checkmark"></span>
                                             </label>
                                        </div>
                                   </div>
                                   <a tabIndex="0" className={`button-check-all ${checkActive ? 'outline-none' : ''}`} onClick={this.props.handleCheckBoxCheckAllChange} onKeyDown={this.checkAll} >Check All</a>
                                   <a tabIndex="0" className={`button-check-all uncheck ${checkActive ? '' : 'outline-none'}`} onClick={this.props.handleCheckBoxUnCheckAllChange} onKeyDown={this.unCheckAll} >UnCheckAll</a>
                              </div>

                         </div>
                    </div>
               </div>
          )
     }
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state),
          comparisonChoosedItems: getComparisonChoosedItems(state),
          comparisonShowExclusionsAdditionalLimitations: getComparisonShowExclusionsAdditionalLimitations(state),
          comparisonShowAlternativeholisticTherapy: getComparisonShowAlternativeholisticTherapy(state),
          comparisonShowAnnualExamRequirement: getComparisonShowAnnualExamRequirement(state)
     };
}

SectionProviderComparison.propTypes = {
     isPreview: PropTypes.bool,
     comparisonChoosedItems: PropTypes.array,
     comparisonShowExclusionsAdditionalLimitations: PropTypes.bool,
     comparisonShowAlternativeholisticTherapy: PropTypes.bool,
     comparisonShowAnnualExamRequirement: PropTypes.bool,
}

const ContentfulSectionProviderComparison = connect(mapStateToProps, mapDispatchToProps)(SectionProviderComparison);

export default ContentfulSectionProviderComparison;
